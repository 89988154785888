<template>
    <div class="aerialView">
        <div class="banner">
            <div class="banner-box">
                <div class="banner-text">
                    <p class="title animate__animated animate__fadeInLeftBig">瞰见· 科技企业全景透视系统</p>
                    <p class="sub-title animate__animated animate__fadeInRightBig">
                        从宏观视角切入，分析不同区域、不同产业的科技企业规模及各类融资事件、科技荣誉的分布<br />
                        挖掘专利价值、关键人才和高潜力企业，提供全面、深入的数据洞察<br />
                        可广泛应用于商业分析、市场研究、决策支持等多种领域<br />帮助企业和机构更好地了解市场、把握机会、优化决策
                    </p>
                    <div class="btn-container animate__animated animate__fadeInUp">
                        <p class="login-btn" @click="experience('login')">{{ isLogin ? '立即使用' : '立即登录' }}</p>
                        <a href="#trial" class="exp-btn">申请体验</a>
                    </div>
                </div>
            </div>
        </div>
        <section class="animate__animated animate__fadeIn">
            <div class="tec">
                <div class="tec-wrap">
                    <div class="tec-content">
                        <p class="title">区域分析</p>
                        <p class="desc">
                            基于区域对各类企业科创数据深度挖掘和分析，为决策提供数据支持和策略指导
                        </p>
                        <div class="tag-main">
                            <p>科技企业规模</p>
                            <p>科技企业分布</p>
                            <p>区域发展潜力</p>
                        </div>
                        <a class="main-button" href="#trial">立即体验</a>
                    </div>
                    <img class="tec-img" src="https://images.zzt.com.cn/gnW/2024/04/30/region-animation.gif" width="810">
                </div>
            </div>
            <div class="track">
                <div class="track-wrap">
                    <img class="track-img" src="https://images.zzt.com.cn/gnW/2024/04/30/industry-animation.gif"
                        width="810">
                    <div class="track-content">
                        <p class="title">产业分析</p>
                        <p class="desc">
                            以产业图谱为核心，分析技术节点的企业规模和发展趋势，并描述产业发展的规律、挖掘高价值企业集群
                        </p>
                        <div class="tag-main">
                            <p>政策法规</p>
                            <p>融资事件</p>
                            <p>科技荣誉</p>
                            <p>专利技术</p>
                        </div>
                        <a class="main-button" href="#trial" id="anchorPoint">立即体验</a>
                    </div>
                </div>
            </div>
        </section>
        <trial id="trial" title="申请瞰见体验" sceneCode="3" />
    </div>
</template>
<script>
import trial from '@/components/trial.vue'
import { mapState } from 'vuex'

export default {
    components: { trial },
    computed: {
        ...mapState(['isLogin'])
    },
    methods: {
        experience() {
            let url = `${process.env.VUE_APP_MODE === 'prod' ? 'https://aerialview.zzt.com.cn/aerialview/region' : 'https://test-aerialview.zzt.com.cn/aerialview/region'}`
            window.open(url)
        },
        handleLogin() {
            console.log('深蓝还没法登录')
        }
    },
    mounted(){
        let url = window.location.href
        if(url.indexOf('#trial') !== -1){
            document.getElementById('anchorPoint').click()
        }
    },
}
</script>
<style lang='scss' scoped>
.main-button {
    @include Button(140px, 36px, #4088EB, 14px, #FFFFFF);
    border: Translate(1px) solid #fff;

    &:hover {
        background: #4299eb;
        color: #fff;
        border: Translate(1px) solid #fff;
    }
}

.aerialView {
    p {
        margin: 0;
    }

    .banner {
        width: 100%;
        min-width: $container-width;
        height: Translate(500px);
        background: url("https://images.zzt.com.cn/ox/2023/04/27/deepblue_bg.png") no-repeat;
        background-position: center center;
        background-size: cover;
        margin: 0 auto;

        // background-image: linear-gradient(-83deg, #68BAFF 0%, #4088EB 100%);
        &-box {
            width: $container-width;
            margin: 0 auto;
            position: relative;
            height: 100%;
        }

        &-text {
            text-align: left;
            color: #fff;

            .title {
                position: absolute;
                top: Translate(110px);
                left: 0;
                color: #333;
                font-size: Translate(40px);
                font-weight: 500;
            }

            .sub-title {
                width: Translate(774px);
                position: absolute;
                top: Translate(180px);
                left: 0;
                color: #666;
                font-size: Translate(16px);
                line-height: Translate(34px);
            }

            .btn-container {
                width: Translate(338px);
                position: absolute;
                top: Translate(340px);
                left: 0;
                display: flex;
                justify-content: space-between;

                .login-btn {
                    @include Button(160px, 40px, #4088eb, 14px, #fff);
                    border: Translate(1px) solid #4088eb;

                    &:hover {
                        background: #337ee5;
                    }
                }

                .exp-btn {
                    @include Button(162px, 42px, #fff, 14px, #4088eb);
                    border: Translate(1px) solid #4088eb;

                    &:hover {
                        background: #4088eb;
                        color: #fff;
                        border: 1px solid #4088eb;
                    }
                }
            }
        }
    }

    section {
        background-color: #fff;

        img {
            font-size: 0;
        }

        .tec,
        .track,
        .mark {
            display: flex;
            align-items: center;
            justify-content: center;
            height: Translate(558px);
            min-width: $container-width;
            max-width: Translate(1920px);
            margin: 0 auto;

            &-wrap {
                width: Translate(338px);
                display: flex;
                width: $container-width;
                justify-content: space-between;
                align-items: center;
            }

            &-content {
                width: Translate(240px);
                text-align: left;

                .title {
                    font-size: Translate(32px);
                    font-weight: 500;
                    color: #333;
                    margin-bottom: Translate(20px);
                }

                .desc {
                    width: Translate(338px);
                    font-size: Translate(16px);
                    color: #666;
                    line-height: Translate(34px);
                    margin-bottom: Translate(5px);
                }

                .tag-main {
                    width: Translate(338px);
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: Translate(43px);

                    p {
                        font-size: Translate(16px);
                        color: #333333;
                        line-height: Translate(32px);
                        display: flex;
                        align-items: center;
                        margin-right: Translate(28px);

                        &::before {
                            content: '';
                            display: inline-block;
                            background: url(https://images.zzt.com.cn/gnW/2024/04/28/select-icon.png) no-repeat;
                            width: Translate(16px);
                            height: Translate(16px);
                            background-position: center center;
                            background-size: cover;
                            margin-right: Translate(5px);
                        }
                    }
                }
            }
        }

        .tec {
            // background: url("https://images.zzt.com.cn/ox/2022/12/12/tec-bg.png") no-repeat;
            width: 100%;
            background-position: center center;
            background-size: cover;
        }

        .track {
            // background: url("https://images.zzt.com.cn/ox/2022/12/23/track-bg.png") no-repeat;
            width: 100%;
            background-position: center center;
            background-size: cover;
            max-width: unset;
        }
    }
}
</style>